const backToTop = document.querySelector('.back-to-top');
window.addEventListener('scroll', function() {
    if (window.scrollY > 100) {
        backToTop.style.opacity = 1;
    } else {
        backToTop.style.opacity = 0;
    }
});
backToTop.addEventListener('click', function () {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
});
