import * as Klaro from "klaro/dist/klaro-no-css";

const config = {
    acceptAll: true,
    mustConsent: true,
    translations: {
        zz: {
            privacyPolicyUrl: '/Datenschutzerklaerung',
            purposes: {
                widgets: {
                    title: 'Widgets'
                },
                analytics: {
                    title: 'Besucher-Statistiken'
                },
            },
        },
    },
    services: [
        {
            name: "bfv-widget",
            purposes: ['widgets'],
            translations: {
                zz: {
                    title: 'BFV Widget',
                    description: 'Vereins-Widgets des Bayerische Fußball-Verband e.V. ',
                },
            },
        },
        {
            name: "matomo",
            purposes: ['analytics'],
            cookies: [
                [/^_pk_.*$/, '/', 'piwik.svgrasheim.de'],
                'piwik_ignore',
            ],
            translations: {
                zz: {
                    title: 'Matomo',
                    description: 'Matomo ist ein einfacher, selbstgehosteter Analytics-Service.',
                },
            },
        },
    ],
};

Klaro.setup(config);

document.querySelector('.klaro-button').addEventListener('click', function (event) {
    event.preventDefault();
    Klaro.show();
});
